<template>
    <div class="verification-trust-badges">
        <StackRouterHeaderBar />

        <main class="main">
            <section class="section overview top">
                <p class="name" v-html="'프로필을 인증하고, 신뢰도를 높이세요!'"></p>
                <div class="circle m-b-24 p-relative">
                    <div class="circle-bold" :class="'p' + trustBadgePercent().toString()" />
                    <!-- <img src="@/assets/images/verification-badge/active/empty.svg" /> -->
                    <div class="flex-row percent p-absolute">
                        <span class="number">{{ trustBadgePercent() }}</span>
                        <span class="sign">%</span>
                    </div>
                </div>

                <!-- <div class="flex items-center"> -->
                <!-- <p class="m-b-16 name" v-html="'프로필을 인증하고<br>신뢰도를 높이세요!'"></p> -->
                <!-- <p
                        class="description"
                        v-html="`1개 인증마다, <span class='c-primary f-bold'>바닐라 50개</span> 획득!`"
                    /> -->
                <!-- </div> -->
            </section>
            <div class="hr" />
            <template v-if="dataLoaded">
                <section
                    class="section-badge"
                    :class="category"
                    v-for="category in Object.keys(verificationTrustBadge)"
                    :key="category"
                >
                    <div class="badge-grid">
                        <div
                            class="item"
                            :class="isActiveOrNot(badge)"
                            v-for="badge in verificationTrustBadge[category]"
                            :key="badge.id"
                            @click="onClickBadge(badge)"
                        >
                            <div class="p-relative m-b-8">
                                <div v-lazy:background-image="imagePath(badge)" class="icon-img" />
                                <div v-if="isUnderReview(badge)" class="under-review">심사중</div>
                            </div>
                            <span class="name">{{ badge.name }}</span>
                        </div>
                    </div>
                </section>
            </template>
        </main>
    </div>
</template>

<script>
import StackRouterHeaderBar from '@/components/app/StackRouterHeaderBar.vue'

export default {
    name: 'VerificationTrustBadgesPage',
    components: { StackRouterHeaderBar },
    data: () => ({
        dataLoaded: false,
        verificationTrustBadge: {
            manner: [],
        },
    }),
    beforeDestroy() {
        this.$store.dispatch('loadMyVerificationBadges')
    },
    computed: {
        myVerificationBadges() {
            return this.$store.getters.myVerificationBadges || []
        },
        gender() {
            return this.$store.getters.me.gender
        },
    },
    methods: {
        async init() {
            try {
                const loadVerificationBadges = () => this.$store.dispatch('loadVerificationBadges')
                const loadMyVerificationBadges = () => this.$store.dispatch('loadMyVerificationBadges')

                const [badges] = await Promise.all([loadVerificationBadges(), loadMyVerificationBadges()])

                badges.forEach(badge => {
                    // badge.id 가 verification_badge_id 라서 그냥 이렇게 박아버림
                    if (badge.category === 'manner' && badge.id >= 15 && badge.id <= 19) {
                        this.verificationTrustBadge[badge.category].push(badge)
                    }
                })

                this.dataLoaded = true
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        imagePath(badge) {
            if (!badge.image_name) {
                return ''
            }

            return require(`@/assets/images/verification-badge/${this.isActiveOrNot(badge)}/${badge.image_name}.svg`)
        },
        isTrustBadge(badge) {
            return badge.verification_badge_id >= 15 && badge.verification_badge_id <= 19
        },
        trustBadgePercent() {
            const badgeCnt = this.$isStudentByProfile() ? 3 : 5
            const num =
                (this.myVerificationBadges.filter(b => {
                    return this.isTrustBadge(b) && b.confirmed
                }).length /
                    badgeCnt) *
                100
            return Math.floor(num)
        },
        onClickBadge(badge) {
            const myBadge = this.myVerificationBadges.find(b => b.verification_badge_id === badge.id)

            switch (badge.id) {
                case 19:
                    // 실명인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'TrustVerification_Click_NameVerif',
                        },
                    })
                    break
                case 18:
                    // 신분증인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'TrustVerification_Click_IDVerif',
                        },
                    })
                    break
                case 17:
                    // 직장인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'TrustVerification_Click_CompanyVerif',
                        },
                    })
                    break
                case 16:
                    // 직업인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'TrustVerification_Click_JobVerif',
                        },
                    })
                    break
                case 15:
                    // 학교인증
                    this.$nativeBridge.postMessage({
                        action: 'sendFirebaseEvent',
                        value: {
                            category: 'TrustVerification_Click_SchoolVerif',
                        },
                    })
                    break
            }

            if (myBadge) {
                if (!myBadge.confirmed) {
                    this.$modal.basic({
                        title: '심사 중',
                        body: '심사에는 1~2영업일이 소요될 수 있으며, 심사 결과는 알림으로 보내드립니다.',
                        buttons: [
                            {
                                label: '확인',
                                class: 'btn-default',
                            },
                        ],
                    })
                } else {
                    myBadge.description = badge.description
                    myBadge.image_name = badge.image_name
                    myBadge.category = badge.category
                    this.$modal.custom({
                        component: 'ModalBadgeInfo',
                        options: {
                            badge: myBadge,
                            from: 'verificationTrustBadgesPage',
                        },
                    })
                    // this.$toast.success('이미 획득한 뱃지에요')
                }

                return
            }

            const realNameBadge = this.verificationTrustBadge.manner.find(b => b.name === '실명인증')
            const myRealNameBadge = this.myVerificationBadges.find(b => b.name === '실명인증')
            if (
                ['manner', 'career', 'financial'].includes(badge.category) &&
                badge.verification_type !== 'auto' &&
                !myRealNameBadge
            ) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Alert_RequestNameverif',
                    },
                })

                this.$modal
                    .basic({
                        body: `'${badge.name}' 뱃지를 획득하기 위해선 먼저 실명인증이 필요합니다`,
                        buttons: [
                            {
                                label: '다음에 하기',
                                class: 'btn-default',
                            },
                            {
                                label: '실명인증하기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$stackRouter.push({
                                name: 'VerificationBadgesCreatePage',
                                props: {
                                    badge: realNameBadge,
                                },
                            })
                        }
                    })
            } else {
                this.$stackRouter.push({
                    name: 'VerificationBadgesCreatePage',
                    props: {
                        badge,
                    },
                })
            }
        },
        isActiveOrNot(badge) {
            return this.myVerificationBadges.find(b => b.verification_badge_id === badge.id && b.confirmed)
                ? 'active'
                : 'inactive'
        },
        isUnderReview(badge) {
            const mVB = this.myVerificationBadges.find(b => b.verification_badge_id === badge.id)
            if (!mVB) return false

            return !mVB.confirmed
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.verification-trust-badges {
    &::-webkit-scrollbar {
        display: none;
    }

    .main {
        width: 100vw;
        height: fit-content;
        overflow-x: hidden;
        overflow-y: auto;

        .hr {
            width: calc(100% - 32px);
            margin-left: 16px;
            height: 1px;
            background-color: $grey-02;
        }
    }

    .tabs {
        .tab.selected {
            color: black;
            border-bottom-color: black;
        }
    }

    .section {
        display: flex;
        flex-direction: column;
        padding: 40px 16px;

        .circle {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 4px solid $grey-02;
        }
        .circle-bold {
            &.p0 {
                display: none;
            }
            &.p20 {
                clip-path: polygon(50% 50%, 50% -50%, 145% 0%);
            }
            &.p40 {
                clip-path: polygon(50% 50%, 50% -50%, 145% 0%, 109% 131%);
            }
            &.p60 {
                clip-path: polygon(50% 50%, 50% -50%, 145% 0%, 109% 131%, -9% 131%);
            }
            &.p80 {
                clip-path: polygon(50% 50%, 50% -50%, 145% 0%, 109% 131%, -9% 131%, -45% 0%);
            }
            &.p33 {
                clip-path: polygon(50% 50%, 50% -50%, 145% 90%);
            }
            &.p66 {
                clip-path: polygon(50% 50%, 50% -50%, 145% 90%, 109% 131%, -9% 131%);
            }
            // &.100 {
            //     clip-path: polygon(50% 50%, 50% -50%, 135% 0%);
            // }
            // clip-path: polygon(50% 50%, 50% -50%, 135% 0%, 135% 100%, -135% 100%);
            margin-top: -4px;
            margin-left: -4px;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            border: 4px solid $purple-primary;
        }

        &-title {
            font-size: 16px;
            color: black;
            font-weight: 500;
            margin-bottom: 16px;

            @include f-medium;
        }

        &.overview {
            padding: 32px 40px 36px 40px;

            .name {
                margin-bottom: 44px;
            }
            .percent {
                width: 100%;
                height: 40px;
                align-items: center;
                font-size: 40px;
                color: $purple-primary;
                left: 55%;
                top: 50%;
                transform: translate(-50%, -50%);

                @include f-bold;

                .number {
                    text-align: right;
                    @include spoqa-f-bold;
                }
                .sign {
                    text-align: left;
                    align-self: flex-end;
                    font-size: 20px;
                    @include spoqa-f-bold;
                }
            }

            .acquired {
                font-size: 18px;
                color: black;

                @include f-medium;
            }

            .acquired-desc {
                color: $grey-08;
                text-align: center;
                line-height: normal;
            }
        }
    }

    .section-badge {
        margin-top: 36px;
        .badge-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            row-gap: 32px;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;

                .name {
                    white-space: nowrap;
                }

                &.inactive > .name {
                    color: $grey-04;

                    @include f-medium;
                }

                &.active > .name {
                    color: $purple-primary;

                    @include f-bold;
                }

                .icon-img {
                    width: 74px;
                    height: 74px;
                    background-size: cover;
                }

                .custom-img {
                    position: absolute;
                    width: 62px;
                    height: 62px;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    &.gray {
                        filter: opacity(25%);
                    }
                }

                .under-review {
                    position: absolute;
                    width: 58px;
                    height: 28px;
                    color: white;
                    background-color: $purple-primary;
                    opacity: 0.9;
                    border-radius: 14px;
                    font-size: 14px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);

                    @include f-bold;
                    @include center;
                }
            }
        }
    }

    .top {
        align-items: center;
        // border-bottom: solid 1px $grey-02;

        .image {
            width: 120px;
        }

        .name {
            font-size: 18px;
            color: black;
            text-align: center;

            @include f-medium;
        }

        .description {
            background-color: $grey-01;
            border-radius: 8px;
            padding: 4px 12px 5px;
            height: 28px;
            font-size: 13px;
            color: $grey-09;
            text-align: center;
            line-height: normal;

            // @include f-bold;
        }
    }
}
</style>
